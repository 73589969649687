<template>
	<div class="wrapper" v-loading="loading">
		<div v-if="!orgId" class="top-btn" ref="topBtn">
            <div>
				<el-select class="w110" v-model="search.search_gist" filterable placeholder="请选择" size="small">
					<el-option v-for="it in searchGist" :key="it.value" :label="it.label" :value="it.value"> </el-option>
				</el-select>
				<el-input class="w140" size="small" placeholder="请输入内容" v-model="search.desp" clearable> </el-input>
				<el-button size="small" type="primary" @click="searchList()">搜索</el-button>
			</div>
            <div>
                <el-select class="w110" size="small" v-model="search.status" clearable @change="searchList()">
					<el-option :key="0" :value="0" label="下架"></el-option>
					<el-option :key="1" :value="1" label="上架"></el-option>
				</el-select>
                <span class="demonstration">创建时间：</span>
				<el-date-picker size="small" style="width: 230px;margin-right:10px" @change="searchList()"
					v-model="search.time"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
                <el-button size="small" type="primary" @click="add()">添加</el-button>
            </div>
		</div>
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
			<el-table-column prop="org_id" label="机构id"></el-table-column>
            <el-table-column prop="org_name" label="机构名"></el-table-column>
            <el-table-column prop="face_value" label="优惠券金额"></el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                   {{ scope.row.admin_status == 0 ? '禁用' : 
                   scope.row.admin_status == 1 ? '正常' : '--' }}
                </template>
            </el-table-column>
            <el-table-column label="上下架状态">
                <template slot-scope="scope">
                   {{ scope.row.status == 0 ? '下架' : 
                   scope.row.status == 1 ? '上架' : '--' }}
                </template>
            </el-table-column>
            <el-table-column prop="limit_type" label="使用类型">
                <template slot-scope="scope">
					<span type="primary">{{ scope.row.limit_type == 1 ? '无门槛' : '满减券' }}</span>
				</template>
            </el-table-column>
            <el-table-column prop="title" label="标题"></el-table-column>
            <el-table-column prop="low_limit" label="使用门槛"></el-table-column>
            <el-table-column prop="price" label="售卖价格"></el-table-column>
            <el-table-column prop="slot_type" label="适用时间">
                <template slot-scope="scope">
					<span type="primary">{{ scope.row.slot_type == 1 ? '领取后计算' : '固定时间' }}</span>
				</template>
            </el-table-column>
            <el-table-column prop="time_slot" label="有效日期">
                <template slot-scope="scope">
					<span type="primary" v-if="JSON.parse(scope.row.time_slot).validity_time">{{ '购买或领取后'+JSON.parse(scope.row.time_slot).validity_time+'天' }}</span>
                    <span type="primary" v-else>{{ JSON.parse(scope.row.time_slot).start_time*1000 | formatDate }} - {{ JSON.parse(scope.row.time_slot).end_time*1000 | formatDate}}</span>
				</template>
            </el-table-column>
            <el-table-column prop="applay_people" label="适用人群">
                <template slot-scope="scope">
					<span v-if="scope.row.apply_people == 1" type="primary">不限人群</span>
                    <span v-if="scope.row.apply_people == 2" type="primary">新生报名</span>
                    <span v-if="scope.row.apply_people == 3" type="primary">老生续费</span>
				</template>
            </el-table-column>
            <el-table-column prop="course_type" label="适用课程">
                <template slot-scope="scope">
					<span v-if="scope.row.course_type == 1" type="primary">门店任意课程可使用</span>
                    <span v-if="scope.row.course_type == 2" type="primary">部门特价课不可用，详情联系机构</span>
				</template>
            </el-table-column>
            <el-table-column prop="creat_member" label="创建人"></el-table-column>
            <el-table-column prop="created_at" label="创建时间">
                <template slot-scope="scope">{{ scope.row.created_at*1000 | formatDate }}</template>
            </el-table-column>
			<el-table-column label="汇总">
                <template slot-scope="scope">
					已售 {{scope.row.sale_count}}
					核销 {{scope.row.hexiao_count}}
				</template>
            </el-table-column>
            <el-table-column label="操作">
				<template slot-scope="scope">
					<el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="{fun: 'handleUp',row: scope.row, change_field: 'admin_status'}">{{ scope.row.admin_status != 1 ? '启用' : '禁用' }}</el-dropdown-item>
                            <el-dropdown-item :command="{fun: 'handleUp',row: scope.row, change_field: 'status'}">{{ scope.row.status != 1 ? '上架' : '下架' }}</el-dropdown-item>
                            <el-dropdown-item :command="{fun: 'handleEdit',row: scope.row.id}">编辑</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
        <add ref="add" :dialogVisible="dialogVisible" :typeFlag="typeFlag" :id="id" @getList="getList" @searchList="searchList" @close="close"></add>
    </div>
</template>
<script>
    import add from './component/add.vue'
    import { couponList, couponChangeStatus } from '@/api/coupon.js';
	export default {
		name: "CouponList",
		props: {
			orgId: { // 机构详情里
				type: String,
				default: ''
			},
		},
		data() {
			return {
				loading: true,
				tableHeight: this.$constant.maxHeight,
                // 列表
				searchGist:  [{
					value: 'org_id',
					label: '机构id'
				}, {
					value: 'org_name',
					label: '机构名'
				}, {
					value: 'title',
					label: '标题'
				}],
				search: {
					search_gist: 'org_name',
                    desp: '',
                    status: '',
                    limit_type: '',
					time: '',
				},
				total: 0,
				limit: 20,
				currentPage: 1,
                list: [],
                // 弹框
                dialogVisible: false,
                typeFlag: 'add',
                id: 0
			}
		},
		components: {
            add,
		},
		mounted() {
			this.getList()
			this.tableHeight = this.orgId ? this.$common.setHeight([], 250) : this.$common.setHeight([this.$refs.topBtn], 130)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.orgId ? this.$common.setHeight([], 250) : this.$common.setHeight([this.$refs.topBtn], 130)
			}, 1000)
		},
		methods: {
			// 获取列表
			getList() {
				let data = {}
				if(this.orgId) {
					data = {
						limit: this.limit,
						page: this.currentPage,
						org_id: this.orgId
					}
				}else {
					data = {
						limit: this.limit,
						page: this.currentPage,
						status: this.search.status,
						org_id: this.search.search_gist == 'org_id' ? this.search.desp : null,
						org_name: this.search.search_gist == 'org_name' ? this.search.desp : null,
						title: this.search.search_gist == 'title' ? this.search.desp : null,
						start_time: this.$common.setSearchTime(this.search.time)[0],
						end_time: this.$common.setSearchTime(this.search.time)[1],
					}
				}
				couponList(data).then((res) => {
					this.total = res.data.total;
                    this.list = res.data.data;
                    this.loading = false;
				}).catch((err) => {
					console.log(err, 222)
				})
            },
            // 搜索
            searchList() {
                this.loading = true;
				this.currentPage = 1;
				this.getList();
            },
			// 页码修改
			handleCurrentChange(val) {
                this.loading = true;
				this.currentPage = val;
				this.getList();
			},
			// 操作回调
			handleCommand(command) {
				this[command.fun](command.row,command.change_field)
			},
			// 上下架
			handleUp(row, change_field){
				let _this = this
				let content = '', change_to = ''
				if(change_field == 'status') { // 上下架
					content = row.status != 1 ? '上架' : '下架'
					change_to = row.status != 1 ? 1 : 0
				}else { // 是否禁用
					content = row.admin_status != 1 ? '启用' : '禁用'
					change_to = row.admin_status != 1 ? 1 : 0
				}
				this.$alert(`是否确定${content}`, '提示', {
					confirmButtonText: '确定',
					callback: action => {
						if(action == 'confirm'){
							couponChangeStatus({
								id: row.id,
								change_field,
								change_to
							}).then((res)=>{
								if(res.code == 200) {
									_this.$message({
										message: content + '成功',
										type: 'success'
									});
									_this.getList()
								}
							}).catch((err)=>{
								console.log(err)
							})
						}
					}
				});
				
			},
            // 新建
            add() {
                this.typeFlag = 'add';
                this.dialogVisible = true;
            },
            // 编辑
            handleEdit(id) {
				this.$refs.add.getInfo(id)
                this.id = id;
                this.typeFlag = 'edit';
                this.dialogVisible = true;
            },
            // 关闭弹框
            close() {
                this.dialogVisible = false
            },
		}
	};
</script>
