import request from '@/util/request'
// 获取优惠券列表
export function couponList(params) {
    return request({
      url: '/admin/v1/coupon/list',
      method: 'get',
      params
    })
}
// 保存修改优惠券
export function couponSave(data) {
  return request({
    url: '/admin/v1/coupon/save',
    method: 'post',
    data
  })
}
// 获取优惠券详情
export function couponDetail(params) {
  return request({
    url: '/admin/v1/coupon/detail',
    method: 'get',
    params
  })
}
// 上下级|(解封停)优惠券
export function couponChangeStatus(data) {
  return request({
    url: '/admin/v1/coupon/changeStatus',
    method: 'POST',
    data
  })
}


// 拼团管理列表
export function groupWorkList(params) {
  return request({
    url: '/admin/v1/groupWork/list',
    method: 'get',
    params
  })
}
//  获取拼团详情
export function groupWorkDetail(params) {
  return request({
    url: '/admin/v1/groupWork/detail',
    method: 'get',
    params
  })
}
