<template>
    <div v-loading="loading">
		<el-checkbox-group v-model="video" @change="selectVideo">
            <el-checkbox v-for="item in videoList" :key="item.id" :label="item.id" :max="10">{{ item.desp }}</el-checkbox>
        </el-checkbox-group>
        <el-pagination v-if="total > limit" style="padding: 6px 0" @current-change="CurrentChange" 
            :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
        </el-pagination>
	</div>
</template>
<script>
import { videoList } from '@/api/courseOrder'
export default {
    name: "VideoSelect",
    props: {
    },
    data() {
        return {
            loading: false,
            org_id: '', // 机构id
            video: [], // 选中视频
            videoList: [],
            currentPage: 1,
            limit: 10,
            total: 0
        }
    },
    methods: {
        clearList() {
            this.loading = false
            this.video = []
            this.currentPage = 1
            this.videoList = []
        },
        // 获取视频列表
        getList(org_id, video) {
            this.loading = true
            if(org_id) {
                this.org_id = org_id  // 第一次调用时赋值机构id,分页时不用再次赋值
                this.currentPage = 1  // 列表从第一页开始
                this.video = video || []  // 清空选中视频
            }
            videoList({
                limit: this.limit,
                page: this.currentPage,
                org_id: org_id || this.org_id,
            }).then((res) => {
                this.total = res.data.total;
                this.videoList = res.data.data;
                this.loading = false;
            }).catch((err) => {
                console.log(err)
            })
        },
        // 页码修改
        CurrentChange(val) {
            this.loading = true;
            this.currentPage = val;
            this.getList();
        },
        // 选中视频
        selectVideo() {
            this.$emit('selectVideo', this.video)
        },
    }
};
</script>