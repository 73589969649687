<template>
    <el-dialog :title="typeFlag =='add'?'添加':'修改'" :visible.sync="dialogVisible" width="700px" :before-close="close" :close-on-click-modal='false'>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
            <el-form-item v-if="typeFlag=='add' && dialogVisible" label="选择机构" prop="org_id">
                <OrgSelect @selectOrg="selectOrg"/>
            </el-form-item>
            <el-form-item label="机构名称" v-else>
					<div>{{org_name}}</div>
				</el-form-item>
            <el-form-item label="优惠券金额" prop="face_value">
                <div><el-input size="small" type="number" v-model="ruleForm.face_value" max="4" style="width: 100px"></el-input></div>
                <el-tag v-for="item in amountList" :key="item" size="small" @click="ruleForm.face_value = item" style="margin-right: 10px;cursor:pointer">{{ item }}</el-tag>
            </el-form-item>
            <el-form-item label="使用限制" prop="limit_type">
                <el-radio-group v-model="ruleForm.limit_type">
                    <el-radio v-for="item in useTypeList" :key="item.type" :label="item.type">{{item.value}}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="使用门槛" prop="low_limit" v-if="ruleForm.limit_type==2">
                <div><el-input size="small" type="number" v-model="ruleForm.low_limit" style="width: 100px"></el-input></div>
                <el-tag v-for="item in conditionList" :key="item" size="small" @click="ruleForm.low_limit = item" style="margin-right: 10px;cursor:pointer">{{ item }}</el-tag>
            </el-form-item>
             <el-form-item label="优惠券标题" prop="title">
                <el-input size="small" v-model="ruleForm.title" clearable></el-input>
            </el-form-item>
            <el-form-item label="售价" prop="saleType">
                <el-radio-group v-model="ruleForm.saleType">
                    <el-radio v-for="item in saleTypeList" :key="item.type" :label="item.type">{{item.value}}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="领取金额" prop="price" v-if="ruleForm.saleType==2">
                <div><el-input size="small" type="number" v-model="ruleForm.price" style="width:100px"></el-input></div>
                <el-tag v-for="item in priceList" :key="item" size="small" @click="ruleForm.price = item" style="margin-right: 10px;cursor:pointer">{{ item }}</el-tag>
            </el-form-item>
            <el-form-item label="有效期" prop="slot_type">
                <el-radio-group v-model="ruleForm.slot_type">
                    <el-radio v-for="item in validityType" :key="item.type" :label="item.type">{{item.value}}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="领取后计算" prop="validity_time" v-if="ruleForm.slot_type==1">
                <el-select size="small" v-model="ruleForm.validity_time" style="width:100px">
                    <el-option
                    v-for="item in validityList"
                    :key="item"
                    :label="item"
                    :value="item"/>
                </el-select>
            </el-form-item>
            <el-form-item label="固定时间" prop="start_end_time" v-if="ruleForm.slot_type==2">
                <el-date-picker
                    v-model="ruleForm.start_end_time"
                    size="small"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="适用人群" prop="apply_people">
                <el-radio-group v-model="ruleForm.apply_people">
                    <el-radio v-for="item in forPeopleList" :key="item.type" :label="item.type">{{item.value}}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="适用课程" prop="course_type">
                <el-radio-group v-model="ruleForm.course_type">
                    <el-radio v-for="item in forCourseList" :key="item.type" :label="item.type">{{item.value}}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="关联视频">
                <VideoSelect ref="video" @selectVideo="selectVideo"/>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
                <el-button @click="close()">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
import OrgSelect from '@/components/orgSelect.vue'
import VideoSelect from '@/components/videoSelect.vue'
import { couponSave, couponDetail } from '@/api/coupon.js'
export default {
    name: "Add",
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        typeFlag: { // 判断是新建/add 编辑/edit 详情
            type: String,
            default: 'add'
        },
        id: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            org_name: '', // 机构名
            amountList: [100, 200, 500], //面值快捷按钮
			useTypeList: [{ type: 1, value: '无门槛' }, { type: 2, value: '满减券' }], //使用限制选项
			conditionList: [500, 1000, 3000], //使用门槛快捷选项
			saleTypeList: [{ type: 1, value: '免费领取' }, { type: 2, value: '付费领取' }], //售卖类型
			validityType: [{ type: 1, value: '领取后计算' }, { type: 2, value: '固定时间' }], //有效期类型选项
			priceList: [1, 9.9, 19.9], //售价快捷选项
			validityList: ['30', '60', '90'], //有效期选项
			forPeopleList: [{ type: 1, value: '新生老生均可领取' }, { type: 2, value: '新生报道' }, { type: 3, value: '老生续费' }],
            forCourseList: [{ type: 1, value: '门店任意课程都可使用' }, { type: 2, value: '部分特价课不可用，详情联系机构' }],
            ruleForm: {
                org_id: '',
                face_value: 100, //面值
				limit_type: 1, //使用限制
				low_limit: 0, //使用门槛
                title: '', //优惠券标题
                saleType: 1, //已选择售卖类型 免费/付费领取
				price: 0, //售价
				slot_type: 1, //有效期类型
                validity_time: '',
                start_end_time: '',
				apply_people: 1, //适用人群
				course_type: 1 //适用课程
            },
            rules: {
                org_id: [
                    { required: true, message: '请选择机构', trigger: 'blur' },
                ],
                face_value: [
                    { required: true, message: '请输入优惠券金额', trigger: 'blur' },
                ],
                limit_type: [
                    { required: true, message: '请选择使用限制', trigger: 'change' },
                ],
                low_limit: [
                    { required: true, message: '请输入使用门槛', trigger: 'blur' },
                ],
                title: [
                    { required: true, message: '请输入优惠券标题', trigger: 'blur' },
                    { max: 25, message: '最多输入25位', trigger: 'blur' }
                ],
                saleType: [
                    { required: true, message: '请选择售价类型', trigger: 'change' },
                ],
                price: [
                    { required: true, message: '请输入价格', trigger: 'blur' },
                ],
                slot_type: [
                    { required: true, message: '请选择有效期类型', trigger: 'change' },
                ],
                start_end_time: [
                    { required: true, message: '请选择有效期', trigger: 'change' },
                ],
                validity_time: [
                    { required: true, message: '请选择有效期', trigger: 'change' },
                ],
                apply_people: [
                    { required: true, message: '请选择适用人群', trigger: 'change' },
                ],
                course_type: [
                    { required: true, message: '请选择适用课程', trigger: 'change' },
                ],
            } 
        }
    },
    components: {
        OrgSelect,
        VideoSelect
    },
    watch: {
        'ruleForm.limit_type': {
            handler(val) {
				if (val == 1) {
					this.ruleForm.low_limit = 0
				}
			}
        },
        'ruleForm.saleType': {
            handler(val) {
				if (val == 1) {
					this.ruleForm.price = 0
				}
			}
        }
    },
    methods: {
        // 选中机构
        selectOrg(org_id) {
            this.ruleForm.org_id = org_id;
            this.$refs.video.getList(org_id);
        },
        // 选中视频
        selectVideo(video_id){
            this.ruleForm.videos = video_id;
        },
        // 回显
        getInfo(id){
            couponDetail({id: id || this.id}).then((res) => {
                for (let item in this.ruleForm) {
                    if(item == 'start_end_time'){
                        if(res.data.slot_type == 2) {
                            this.ruleForm.start_end_time = [JSON.parse(res.data.time_slot).start_time*1000, JSON.parse(res.data.time_slot).end_time*1000]
                        }
                    } else {
                        this.ruleForm[item] = res.data[item]
                    }
                }
                this.org_name = res.data.org_name
                this.ruleForm.saleType = Number(res.data.price) > 0 ? 2 : 1
                this.$refs.video.getList(res.data.org_id, res.data.video_list.map(item=>item.id)); // 视频列表
            }).catch((err) => {
                console.log(err)
                this.close();
            })
        },
        // 创建
        submitForm(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 判断机构id
                    this.save()
                }
            });
        },
        save() {
            let params = { ...this.ruleForm }
            if (this.typeFlag == 'edit') { params.id = this.id }
            if (this.ruleForm.slot_type == 2) {
                delete params.validity_time;
                params.start_time = this.ruleForm.start_end_time[0]/1000
                params.end_time = this.ruleForm.start_end_time[1]/1000
            }
            delete params.saleType; // 去掉售卖类型
            delete params.start_end_time; // 去掉时间
            couponSave(params).then((res) => {
                this.$message({
                    message: res.msg,
                    type: res.code != 200 ? 'warning' : 'success'
                });
                if (res.code == 200) {
                    this.close();
                    this.typeFlag == 'edit' ? this.$emit('getList') : this.$emit('searchList')
                }
            }).catch((err) => {
                console.log(err)
                this.close();
            })
        },
        // 关闭
        close() {
            this.ruleForm = {
                org_id: '',
                face_value: 100, //面值
				limit_type: 1, //使用限制
				low_limit: 0, //使用门槛
                title: '', //优惠券标题
                saleType: 1, //已选择售卖类型 免费/付费领取
				price: 0, //售价
				slot_type: 1, //有效期类型
                validity_time: '',
                start_end_time: '',
				apply_people: 1, //适用人群
				course_type: 1 //适用课程
            }
            this.$refs.video.clearList(); // 清空视频列表
            this.$emit('close')
        }
    }
};
</script>
<style lang="less" scoped>
.el-radio-group {
    vertical-align: baseline;
}
</style>